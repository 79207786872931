@font-face {
  font-family: "Aileron-Regular";
  src: url("./fonts/Aileron-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Aileron-Italic";
  src: url("./fonts/Aileron-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.9) translateY(20px); /* Starts smaller and slightly lower */
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0); /* Ends at normal scale and original position */
    opacity: 1;
  }
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Aileron-Regular", sans-serif;
  background-color: #4205ba;
  overflow-x: hidden;
}

.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #ffffff;
  animation: fadeIn 1.2s ease-in-out;
}

.logo-image {
  width: 28vw;
  height: auto;
  margin-bottom: 50px;
  transition: width 0.3s;
  animation: scaleUp 1s ease-in-out;
}

/* Text Wrapper */
.text-wrapper {
  text-align: center;
  max-width: 60vw;
  margin: 0 auto;
}

.carousel-text {
  font-size: 1.8rem;
  opacity: 0;
  transform: translateY(-20px); /* Start 20px above */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.fade-out {
  opacity: 0;
  transform: translateY(-20px);
}

/* Contact Text */
.contact-text {
  font-size: 1.4rem;
  text-align: center;
  position: absolute;
  bottom: 10vh;
  animation: scaleUp 1s ease-in-out;
}

.itallics {
  font-family: "Aileron-Italic";
}

/* Laptop View */
@media (min-width: 1024px) {
  .logo-image {
    width: 28vw;
    position: absolute;
    top: 20%;
    margin-bottom: 50px;
  }
  .text-wrapper {
    max-width: 60vw;
    position: absolute;
    top: 50%;
  }
  .carousel-text {
    font-size: 2.5rem;
  }
  .contact-text {
    font-size: 1.8rem;
    margin-bottom: 0%;
    position: absolute;
  }
}

/* Tablet View */
@media (max-width: 1023px) and (min-width: 768px) {
  .logo-image {
    width: 50vw;
    position: absolute;
    top: 15%;
    margin-bottom: 60px;
  }
  .text-wrapper {
    max-width: 70vw;
  }
  .carousel-text {
    font-size: 2rem;
  }
  .contact-text {
    font-size: 1.5rem;
    margin-bottom: 8%;
    position: absolute;
  }
}

/* Mobile Portrait */
@media (max-width: 767px) and (orientation: portrait) {
  .logo-image {
    width: 60vw;
    position: absolute;
    top: 10%;
    left: 10%;
  }
  .text-wrapper {
    max-width: 80vw;
    position: absolute;
    top: 25%;
  }
  .carousel-text {
    font-size: 1.8rem;
    text-align: start;
  }
  .contact-text {
    font-size: 1rem;
    margin-bottom: 8%;
    position: absolute;
  }
}

.email-link {
  color: #ffffff;
  text-decoration: none;
}

/* Mobile Landscape & Tablet Landscape */
@media (max-width: 1023px) and (orientation: landscape) {
  .carousel-container {
    min-height: 100vh;
    overflow-y: auto;
    padding: 20px;
  }
  .logo-image {
    width: 25vw;
    position: absolute;
    top: 7%;
    margin-bottom: 40px;
  }
  .text-wrapper {
    max-width: 70vw;
    position: absolute;
    top: 30%;
  }
  .carousel-text {
    font-size: 1.2rem;
  }
  .contact-text {
    font-size: 0.9rem;
    margin-bottom: 8%;
    position: absolute;
  }
}
